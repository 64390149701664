import React from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import { Button } from 'components/ui/button'

export default function HomeView() {
	function openAppStoreApp() {
		window.location.href = 'https://apps.apple.com/us/app/foodtwosteps/id6733241671'
	}

	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8'>
				<div className='mx-auto max-w-2xl py-2 sm:py-8 lg:py-16'>
					<div className='text-center'>
						<img
							className='bg-white mx-auto mb-10 h-52 content-center'
							style={{ padding: '4px', borderRadius: '40px' }}
							src='/image/icon_rounded.png'
							alt=''
						/>
						<h1 className='text-4xl font-bold tracking-tight sm:text-6xl'>Вкусные места рядом</h1>
						<p className='mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400'>
							Откройте вкусные кафе и рестораны рядом! Наслаждайтесь изысканными блюдами и фирменными напитками всего в
							нескольких шагах.
						</p>
						<div className='mt-10 flex items-center justify-center gap-x-6'>
							<Button className='w-full p-7 sm:w-48' onClick={openAppStoreApp}>
								<svg
									className='mr-3 w-8 h-8'
									aria-hidden='true'
									focusable='false'
									data-prefix='fab'
									data-icon='apple'
									role='img'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 384 512'>
									<path
										fill='currentColor'
										d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'></path>
								</svg>
								<div className='text-left mr-3'>
									<div className='mb-1 text-xs'>Загрузить в</div>
									<div className='-mt-1 font-sans text-sm font-semibold'>App Store</div>
								</div>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
